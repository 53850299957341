import React, { useState } from 'react';
import TLDR from '../About/TLDR';
import LAM from '../About/LAM';
import { Container, Row } from 'react-bootstrap';

const Toggle = () => {
  let [toggle, setToggle] = useState(true);

  const handleClick = () => {
    setToggle(!toggle);
  };

  return (
    <>
      <Container>
        <Row>
          <button onClick={handleClick} className='toggle-switch'>
            {toggle ? 'TL;DR' : 'About Me'}
          </button>
        </Row>
        <Row>
          <div>{toggle ? <LAM /> : <TLDR />}</div>
        </Row>
      </Container>
    </>
  );
};

export default Toggle;
