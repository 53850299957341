import React from 'react';

const TLDR = () => {
  return (
    <div>
      <p>
        I'm Misti Dawn Dinzy—a web developer with a lot of passion, a touch of
        whimsy, and an unwavering dedication to personal and professional
        growth. I'm here to create, learn, and make a difference, one line of
        code at a time.
      </p>
    </div>
  );
};

export default TLDR;
