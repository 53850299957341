import { Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <div className='header'>
      <Navbar variant="dark">
        <Navbar.Brand as={Link} to='/'>
          mdnzy
        </Navbar.Brand>
        <Nav className='header-links'>
          <Nav.Link as={Link} to='/'>
            Home
          </Nav.Link>
          <Nav.Link as={Link} to='/about'>
            About
          </Nav.Link>
          <Nav.Link as={Link} to='/gallery'>
            Projects
          </Nav.Link>
          <Nav.Link as={Link} to='/resume'>
            Resume
          </Nav.Link>
          <Nav.Link href='https://linktr.ee/mdinzy' target='blank'>
            LinkTree
          </Nav.Link>
        </Nav>
      </Navbar>
    </div>
  );
}

export default Header;
