import { Switch, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Stars from './components/Neat/Stars/Stars';
import Header from './components/Header/Header';
import Home from './components/Home/Home';
import About from './components/About/About';
import Gallery from './components/Gallery/Gallery';
import Resume from './components/Resume/Resume';
import Laptop from './components/Neat/Laptop';

function App() {
  return (
    <>
      <div className='app'>
      <Stars/>
        <Header />
        <hr />
        <div className='app-content'>
          <Switch>
            <Route exact path='/'>
              <Home />
            </Route>
            <Route path='/about'>
              <About />
            </Route>
            <Route path='/gallery'>
              <Gallery />
            </Route>
            <Route path='/resume'>
              <Resume />
            </Route>
            <Route path='/laptop'>
              <Laptop />
            </Route>
          </Switch>
        </div>
        <hr />
      </div>
    </>
  );
}

export default App;
