import misti from './Images/miskiRiku.jpg';

function Home() {
  return (
    <div className='home'>
      <div className='card-box'>
        <h1>Hello! I'm Misti.</h1>
        <img src={misti} alt='Misti' />
        <h2>Full-Stack Web + Software Developer</h2>
        <hr />
      </div>
    </div>
  );
}

export default Home;
