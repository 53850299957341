import { Col, Container, Row } from 'react-bootstrap';
import projects from '../../data/projects.json';
import ProjectCard from '../Projects/ProjectCard';
import ProjectsAccordion from '../Projects/ProjectsAccordion';
import Laptop from '../Neat/Laptop';

export default function Gallery() {
  return (
    <div className='gallery'>
      <Container>
        <Row>
          <Col>
            <Laptop />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          {projects.map((project, id) => (
            <ProjectCard key={id} project={project} />
          ))}
        </Row>
      </Container>
      <Container>
        {projects.map((project, id) => (
          <ProjectsAccordion
            description={project.description}
            key={id}
            id={id}
            image_url={project.image_url}
            image_url2={project.image_url2}
            technologies={project.technologies}
            title={project.title}
          />
        ))}
      </Container>
    </div>
  );
}
