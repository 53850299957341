import { useState } from 'react';
import { Card, Modal } from 'react-bootstrap';

export default function ProjectCard(props) {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  return (
    <>
      <Card
        bg='dark'
        className='project'
        onClick={handleShow}
        style={{ width: '18rem' }}
      >
        <Card.Header className='card-hdr'>{props.project.title}</Card.Header>
        <Card.Img src={props.project.image_url} />
        <Card.Footer className='card-footer'>
          <small className='code-tech'>Built with:</small>
          <br />
          <span className='code'>&lt;</span>
          <small className='code-tech'>{props.project.technologies}</small>
          <span className='code'>&#47;&gt;</span>
        </Card.Footer>
      </Card>
      <Modal
        {...props}
        animation
        onHide={handleClose}
        show={show}
        size='lg'
        centered
      >
        <Modal.Header closeButton>{props.project.title}</Modal.Header>
        <Modal.Body>
          <Card bg='dark'>
            <Card.Img
              src={props.project.image_url}
              alt={props.project.title}
              key={props.project.id}
            />
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <small>Built with:</small>
          <br />
          <span className='code'>&lt;</span>
          <small>{props.project.technologies}</small>
          <span className='code'>&#47;&gt;</span>
        </Modal.Footer>
      </Modal>
    </>
  );
}
