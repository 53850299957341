import { Accordion, Card, Carousel } from 'react-bootstrap';

export default function ProjectsAccordion(props) {
  return (
    <>
      <Accordion flush>
        <Accordion.Item eventKey={props.id}>
          <Accordion.Header>{props.title}</Accordion.Header>
          <Accordion.Body>
            <Card>
              <Carousel variant='dark'>
                <Carousel.Item>
                  <Card>
                    <Card.Img
                      src={props.image_url}
                      alt={props.title}
                      key={props.id}
                    />
                  </Card>
                </Carousel.Item>
                <Carousel.Item>
                  <Card>
                    <Card.Img
                      src={props.image_url2}
                      alt={props.title}
                      key={props.id}
                    />
                  </Card>
                </Carousel.Item>
              </Carousel>
            </Card>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}
