import { Container, Row } from 'react-bootstrap';
import ToggleSwitch from '../Toggle/Toggle';

export default function About() {
  return (
    <div className='about'>
      <Container className='about-me-desc'>
        <Row>
          <ToggleSwitch />
        </Row>
        <hr />
      </Container>
    </div>
  );
}
