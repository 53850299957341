import { Container } from 'react-bootstrap';

export default function Resume() {
  return (
    <Container>
      <div className='resume'>
        <h1>My Resume</h1>
        <hr />
        <iframe
          src='https://drive.google.com/file/d/1N3wSMKIP8g_Ty3-rZPTsLqh6qn0PkTlV/preview'
          width='640'
          height='875'
          title='resume'
        ></iframe>
      </div>
    </Container>
  );
}
