import vid from '../../assets/images/salmoncookies-home.mp4';
import './Laptop.css';
import { Card } from 'react-bootstrap';

function Laptop() {
  return (
    <div>
      <Card bg='dark' className='project' style={{ width: '30rem' }}>
        <Card.Body>
          <video
            autoPlay
            loop
            muted
            width='100%'
            height='100%'
            className='fish'
          >
            <source src={vid} type='video/mp4' />
          </video>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Laptop;
